import { h, JSX } from 'preact';
import { forwardRef } from 'preact/compat';
import { Z_INDEXES } from '../utilities/constants.ts';
import { CloseIcon } from '../../shared/CloseIcon.jsx';
import { useScrollStrategy } from '../hooks/useScrollStrategy.tsx';

const DIALOG_TOP = '42px';

export const KEYBOARD_NAV_DIALOG_STYLES = `
  dialog {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    left: 1rem;
    position: absolute;
    right: 1rem;
    top: ${DIALOG_TOP};
    border-radius: 8px;
    font-family: var(--font-family);
    padding: 16px 0;
  }

  .dialog-container {
    position: relative;
    transform: scale(1);
    z-index: ${Z_INDEXES.KEYBOARD_NAV_DIALOG};
  }

  .dialog-container.sticky {
    top: calc(var(--auto-scroll-margin-top));
    position: sticky;
  }

  .dialog-grid-container {
    display: grid;
    gap: 16px;
    grid-template-columns: minmax(0, 1fr);
  }

  dialog:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--accent-color), inset 0 0 0 3px white;
  }

  kbd {
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    box-shadow: 0 0 0 1px white;
    font-family: monospace;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
  }

  dialog ul {
    padding: 0 16px;
    list-style: none;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    margin: 0;
  }

  dialog li {
    display: grid;
    gap: 8px;
    align-items: center;
    font-size: .875rem;
    grid-template-columns: minmax(0, 1fr) auto;
  }

  dialog h2 {
    font-size: 1.25rem;
    margin: 0;
  }

  dialog hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin: 0;
  }

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .close-button {
    align-items: center;
    background: none;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    opacity: 0.7;
    outline: none;
    padding: 6px;
    width: 24px;
  }

  .close-button:focus-visible, .close-button:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

type KeyboardNavDialogProps = { onClickClose: () => void };

export const KeyboardNavDialog = forwardRef<HTMLDialogElement, KeyboardNavDialogProps>(
  ({ onClickClose }, ref): JSX.Element => {
    const { scrollStrategy } = useScrollStrategy();

    return (
      <div class={`dialog-container ${scrollStrategy === 'whole-page' ? 'sticky' : ''}`}>
        <dialog ref={ref}>
          <div class="dialog-grid-container">
            <div class="dialog-header">
              <h2>Keyboard Shortcuts</h2>
              <button
                type="button"
                onClick={onClickClose}
                class="close-button"
                aria-label="Close keyboard navigation guide"
                tabIndex={0}
              >
                <CloseIcon color="#ffffff" />
              </button>
            </div>
            <hr />
            <ul>
              <li>
                Navigate transcript paragraphs and chapters
                <span>
                  <kbd>Tab</kbd> , <kbd>Shift</kbd> + <kbd>Tab</kbd>
                </span>
              </li>
              <li>
                Seek to selected paragraph or chapter
                <kbd>Enter</kbd>
              </li>
              <li>
                Play/pause
                <kbd>p</kbd>
              </li>
              <li>
                Toggle auto-scroll
                <kbd>a</kbd>
              </li>
              <li>
                Show keyboard shortcuts
                <kbd>?</kbd>
              </li>
              <li>
                Hide keyboard shortcuts
                <kbd>Esc</kbd>
              </li>
            </ul>
          </div>
        </dialog>
      </div>
    );
  },
);
