import { ComponentChildren, Fragment, h, JSX } from 'preact';
import { CUE_CLASSNAME } from '../utilities/constants.ts';

export const CUE_STYLES = `
  .current-cue {
    background-color: var(--cue-color);
    border-radius: 4px;
    padding: 2px;
    margin: -2px;
  }
`;

type CueProps = {
  children: ComponentChildren;
  endTimeMs: number;
  startTimeMs: number;
};

export const Cue = ({ children, startTimeMs, endTimeMs }: CueProps): JSX.Element => {
  return (
    <Fragment>
      <span data-start-time-ms={startTimeMs} data-end-time-ms={endTimeMs} class={CUE_CLASSNAME}>
        {children}
      </span>{' '}
    </Fragment>
  );
};
