import { ComponentChildren, h, JSX } from 'preact';
import { useRef } from 'preact/hooks';
import { useWistiaPlayerContext } from '../hooks/useWistiaPlayerContext.tsx';
import { countMetric } from '../../../utilities/simpleMetrics.js';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';

export const PARAGRAPH_STYLES = `
  p {
    border-radius: 4px;
    font-family: var(--font-family);
    line-height: 1.5;
    margin: 0;
    outline: none;
    padding: 6px 8px;
    transition: box-shadow 0.1s;
  }

  p:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-color);
  }
`;

type ParagraphProps = { children: ComponentChildren };

export const Paragraph = ({ children }: ParagraphProps): JSX.Element => {
  const ref = useRef<HTMLParagraphElement>(null);
  const { player } = useWistiaPlayerContext();
  const { mediaHashedId } = useTranscriptContext();

  const onKeyDown = (event: KeyboardEvent) => {
    if (![' ', 'Enter'].includes(event.key)) {
      return;
    }

    event.preventDefault();

    const firstWordInParagraph = ref.current?.querySelector('.timed-word');
    if (firstWordInParagraph && firstWordInParagraph instanceof HTMLElement) {
      const startTime = Number(firstWordInParagraph.dataset.startTime);
      player.time(startTime / 1000);
      if (player.state() === 'beforeplay') {
        player.play();
      }

      countMetric('transcript/seek-by-paragraph', 1, {
        mediaHashedId,
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions
    <p ref={ref} part="paragraph" tabIndex={0} onKeyDown={onKeyDown}>
      {children}
    </p>
  );
};
