import { useSignalEffect } from '@preact/signals';
import { addPart, removePart } from '../utilities/domUtils.ts';
import { useTranscriptContext } from './useTranscriptContext.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

export const useCurrentWordDOMManipulator = (): void => {
  const { transcriptScrollContainer } = useTranscriptContext();
  const { currentWordElement, shouldHighlight } = useTranscriptSignals();

  useSignalEffect(() => {
    if (!shouldHighlight.value) {
      return;
    }

    const newCurrentWordElem = currentWordElement.value;
    const oldCurrentWordElem = transcriptScrollContainer?.querySelector('.current-word');

    oldCurrentWordElem?.classList.remove('current-word');
    removePart(oldCurrentWordElem, 'current-word');

    newCurrentWordElem?.classList.add('current-word');
    addPart(newCurrentWordElem, 'current-word');
  });
};
