import { useCurrentCueDOMManipulator } from './useCurrentCueDOMManipulator.ts';
import { useCurrentWordAndCueTrackerData } from './useCurrentWordAndCueTrackerData.ts';
import { useCurrentWordDOMManipulator } from './useCurrentWordDOMManipulator.ts';
import { useSetCurrentWordSignal } from './useSetCurrentWordSignal.ts';
import { CurrentWordAndCueTrackerProps } from '../types.ts';
import { useSetShouldHighlightSignal } from './useSetShouldHighlightSignal.ts';
import { useSetPlayerStateSignal } from './useSetPlayerStateSignal.ts';

export const useCurrentWordAndCueTracking = ({
  captionsData,
  transcriptDocument,
}: CurrentWordAndCueTrackerProps): void => {
  const { currentCueStartTimeMs, currentWordStartTime } = useCurrentWordAndCueTrackerData({
    captionsData,
    transcriptDocument,
  });

  useSetPlayerStateSignal();

  useSetCurrentWordSignal({
    currentWordStartTime,
  });
  useCurrentWordDOMManipulator();
  useCurrentCueDOMManipulator({ currentCueStartTimeMs });
  useSetShouldHighlightSignal();
};
