import { useEffect, useMemo } from 'preact/hooks';
import { isEmptyArray, isNil } from '@wistia/type-guards';
import { Font } from '../../forms/utilities/styleHelpers.ts';
import {
  SupportedGoogleFont,
  getCurrentFontsInDocument,
  getLoadedSelfHostedGoogleFonts,
  loadSelfHostedGoogleFont,
} from '../../../utilities/fonts.ts';

const getLoadedGoogleFonts = () => {
  const linkElements = document.querySelectorAll<HTMLLinkElement>(
    'link[rel="stylesheet"][href^="https://fonts.googleapis.com/css"]',
  );

  if (linkElements.length === 0) return [];

  return Array.from(linkElements)
    .map(({ href }) => {
      const url = new URL(href);
      const fontFamilies = url.searchParams.get('family');
      return fontFamilies?.split('|');
    })
    .flat()
    .filter(Boolean);
};

export const useCustomAndGoogleFonts = (fonts: Font[], shouldUseGoogleApi = true): void => {
  if (isEmptyArray(fonts)) {
    return;
  }

  // START GOOGLE FONTS
  const googleFonts = fonts.filter(({ url }) => isNil(url)).map(({ fontFamily }) => fontFamily);

  const loadedGoogleFonts = shouldUseGoogleApi
    ? getLoadedGoogleFonts()
    : getLoadedSelfHostedGoogleFonts();
  const encodedFonts = googleFonts
    .filter((fontName) => !loadedGoogleFonts.includes(fontName))
    .map((fontName) => encodeURIComponent(fontName).replace('%20', '+'));

  useEffect(() => {
    if (isEmptyArray(encodedFonts)) {
      return;
    }

    if (shouldUseGoogleApi) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `https://fonts.googleapis.com/css?family=${encodedFonts.join('|')}`;
      document.head.appendChild(link);
    } else {
      encodedFonts.forEach((encodedFontName) => {
        loadSelfHostedGoogleFont(encodedFontName as SupportedGoogleFont);
      });
    }
  }, [encodedFonts]);

  // END GOOGLE FONTS, START CUSTOM FONTS
  const currentFontsInDocument = useMemo(getCurrentFontsInDocument, [document.fonts]);

  const customFonts = fonts
    .filter(({ url }) => !isNil(url))
    .filter(({ fontFamily }) => !currentFontsInDocument.has(fontFamily));

  useEffect(() => {
    if (isEmptyArray(customFonts)) {
      return;
    }

    customFonts.forEach(({ fontFamily, url }) => {
      const fontFace = new FontFace(fontFamily, `url(${url})`);
      void fontFace.load().then(() => {
        document.fonts.add(fontFace);
      });
    });
  }, [customFonts]);
};
