import { useEffect } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { PlayerState } from '../../../types/player-api-types.ts';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

export const useSetPlayerStateSignal = (): void => {
  const { player } = useWistiaPlayerContext();
  const { playerState } = useTranscriptSignals();

  useEffect(() => {
    return player.bind('statechange', (state: PlayerState) => {
      playerState.value = state;
    });
  }, [player]);
};
