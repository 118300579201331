import { useMemo } from 'preact/hooks';
import { isNil } from '@wistia/type-guards';
import {
  CaptionsData,
  CurrentWordAndCueTrackerProps,
  SlateNode,
  TimedWord,
  TranscriptDocument,
} from '../types.ts';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { useCurrentPlayerTime } from '../../shared/hooks/useCurrentPlayerTime.ts';

type CueStartTimesToEndTimes = Map<number, number>;

const getTimedWordsMapFromTranscriptDocument = (
  transcriptDocument: TranscriptDocument,
): Map<number, TimedWord> => {
  const timedWords = new Map<number, TimedWord>();
  const nodesToSearch = [...transcriptDocument];
  const getTimedWordsFromNode = (node: SlateNode): void => {
    if (node.type !== 'timed-words') {
      return;
    }
    node.words.forEach(([word, startTime, endTime]) => {
      timedWords.set(startTime, [word, startTime, endTime]);
    });
  };

  while (nodesToSearch.length > 0) {
    const node = nodesToSearch.shift();
    if (node) {
      getTimedWordsFromNode(node);
      if (node.children) {
        nodesToSearch.push(...node.children);
      }
    }
  }

  return timedWords;
};

const getCueStartTimesToEndTimes = (captionsData: CaptionsData): CueStartTimesToEndTimes => {
  const cueStartTimeToEndTimeMap = new Map<number, number>();
  captionsData.forEach((cue) => {
    cueStartTimeToEndTimeMap.set(cue[0], cue[1]);
  });
  return cueStartTimeToEndTimeMap;
};

export type CurrentWordAndCueTrackerData = {
  currentCueStartTimeMs: number | undefined;
  currentWordStartTime: number | undefined;
};

export const useCurrentWordAndCueTrackerData = ({
  captionsData,
  transcriptDocument,
}: CurrentWordAndCueTrackerProps): CurrentWordAndCueTrackerData => {
  const { player } = useWistiaPlayerContext();
  const timeFromPlayerMs = useCurrentPlayerTime(player);

  const timedWords = useMemo(
    () => getTimedWordsMapFromTranscriptDocument(transcriptDocument),
    [transcriptDocument],
  );

  const cueStartTimesToEndTimes = useMemo(
    () => getCueStartTimesToEndTimes(captionsData),
    [captionsData],
  );

  const cueStartTimes = useMemo(
    () => Array.from(cueStartTimesToEndTimes.keys()),
    [cueStartTimesToEndTimes],
  );

  const timedWordsArray = useMemo(() => Array.from(timedWords.values()), [timedWords]);

  // binary search for efficiency, since there can be many cues, and we know
  // they are sorted
  const currentCueStartTimeMs = useMemo(() => {
    if (timeFromPlayerMs == null) {
      return undefined;
    }
    let low = 0;
    let high = cueStartTimes.length - 1;
    let mid = 0;
    while (low <= high) {
      mid = Math.floor((low + high) / 2);
      const startTime = cueStartTimes[mid];
      const endTime = cueStartTimesToEndTimes.get(startTime);

      if (endTime != null && timeFromPlayerMs >= startTime && timeFromPlayerMs < endTime) {
        return startTime;
      }

      if (timeFromPlayerMs < startTime) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
    return undefined;
  }, [cueStartTimes, cueStartTimesToEndTimes, timeFromPlayerMs]);

  const timedWordsInCurrentCue = useMemo(() => {
    if (currentCueStartTimeMs == null || timeFromPlayerMs == null) {
      return undefined;
    }

    const cueEndTime = cueStartTimesToEndTimes.get(currentCueStartTimeMs);

    if (cueEndTime == null) {
      return undefined;
    }

    const firstWordOfCurrentCue = timedWords.get(currentCueStartTimeMs);

    // If the first word of the current cue is not in the timed words map, then we need to
    // do a full search for the words in the current cue.
    if (isNil(firstWordOfCurrentCue)) {
      return timedWordsArray.filter(
        ([, startTime, endTime]) => startTime >= currentCueStartTimeMs && endTime <= cueEndTime,
      );
    }

    const firstWordOfCurrentCueIndex = timedWordsArray.indexOf(firstWordOfCurrentCue);

    const timedWordsInCurrentCueResult: TimedWord[] = [];

    for (let i = firstWordOfCurrentCueIndex; i < timedWordsArray.length; i++) {
      const [, startTime, endTime] = timedWordsArray[i];
      if (startTime >= currentCueStartTimeMs && endTime <= cueEndTime) {
        timedWordsInCurrentCueResult.push(timedWordsArray[i]);
      }

      // If the start time of our current word is greater than the end time of the current cue,
      // we know we've gone past the end of the current cue, so we can break out of the loop.
      if (startTime > cueEndTime) {
        break;
      }
    }

    return timedWordsInCurrentCueResult;
  }, [currentCueStartTimeMs, timeFromPlayerMs, timedWordsArray, cueStartTimesToEndTimes]);

  // Given that we have the current cue start time, we know the current word's
  // start time is going to be the start of one of the words in the current cue.
  // We also know that the cue start time is exactly equal to the start time of
  // its first word, we can start searching at the index of the word with the
  // same start time as the current cue.
  const currentWordStartTime = useMemo(() => {
    if (timedWordsInCurrentCue == null || timeFromPlayerMs == null) {
      return undefined;
    }

    const currentWord = timedWordsInCurrentCue.find((word) => {
      const [, startTime, endTime] = word;
      return timeFromPlayerMs >= startTime && timeFromPlayerMs < endTime;
    });

    return currentWord ? currentWord[1] : undefined;
  }, [currentCueStartTimeMs, timedWords, timeFromPlayerMs]);

  return {
    currentCueStartTimeMs,
    currentWordStartTime,
  };
};
