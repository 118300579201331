import { Fragment, h, JSX } from 'preact';
import { useWistiaPlayerContext } from '../hooks/useWistiaPlayerContext.tsx';
import { Z_INDEXES } from '../utilities/constants.ts';
import { countMetric } from '../../../utilities/simpleMetrics.js';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';
import { useAutoEnableAutoScroll } from '../hooks/useAutoEnableAutoScroll.ts';
import { ExtendedAudioDescriptionControl } from '../types.ts';

export const TIMED_WORD_STYLES = `
  @keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scaleY(1.05) scaleX(1.025);
    }
    100% {
      transform: scale(1);
    }
  }

  .timed-word {
    font-family: var(--font-family);
    appearance: none;
    outline: none;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
    border-radius: 4px;
    border: none;
    padding: 2px;
    margin: -2px;
    z-index: ${Z_INDEXES.TIMED_WORD};
  }

  .timed-word.current-word {
    background-color: var(--current-word-background-color);
    animation: pop 200ms ease-in-out;
    color: var(--current-word-text-color);
    z-index: ${Z_INDEXES.CURRENT_WORD};

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .timed-word:hover {
      background-color: var(--timed-word-hover-background-color);
      color: var(--current-word-text-color);
      position: relative;
      z-index: ${Z_INDEXES.HOVERED_TIMED_WORD};
    }
  }
`;

type TimedWordProps = {
  isLastWord?: boolean;
  startTime: number;
  word: string;
};

export const TimedWord = ({ word, startTime, isLastWord = false }: TimedWordProps): JSX.Element => {
  const { player } = useWistiaPlayerContext();
  const { mediaHashedId } = useTranscriptContext();
  const autoEnableAutoScroll = useAutoEnableAutoScroll();

  const onClickTimedWord = () => {
    player.time(startTime / 1000);
    if (player.state() === 'beforeplay') {
      player.play();
    }

    player.time(startTime / 1000);

    const eadControl = player._impl?.controls.extendedAudioDescriptionButton as
      | ExtendedAudioDescriptionControl
      | undefined;

    if (eadControl?.isAudioPlaying()) {
      eadControl.stopPlayingAnyCue();
      player.play();
    }

    autoEnableAutoScroll();

    countMetric('transcript/seek-by-word', 1, {
      mediaHashedId,
    });
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span
        part="timed-word"
        className="timed-word"
        data-start-time={startTime}
        onClick={onClickTimedWord}
      >
        {word}
      </span>
      {isLastWord ? null : ' '}
    </Fragment>
  );
};
