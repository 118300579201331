import { useState } from 'preact/hooks';
import { PublicApi } from 'src/types/player-api-types.ts';
import { useInterval } from '../../../utilities/useInterval.js';

const CURRENT_TIME_INTERVAL = 150;

export const useCurrentPlayerTime = (player: PublicApi | null): number | null => {
  const [result, setResult] = useState<number | null>(null);

  useInterval(() => {
    if (player != null) {
      setResult(Math.round(player.time() * 1000));
    }
    if (player == null) {
      return;
    }

    setResult(
      Math.round(
        player.time() * 1000 + (player.state() === 'playing' ? CURRENT_TIME_INTERVAL / 2 : 0),
      ),
    );
  }, CURRENT_TIME_INTERVAL);

  return result;
};
