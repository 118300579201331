import { h, JSX } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { KeyboardNavDialog } from '../components/KeyboardNavDialog.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

type KeyboardEventWithWistiaHandling = KeyboardEvent & {
  _handledByWistia?: boolean;
};

type UseTranscriptKeyboardNavigationReturnValue = {
  KeyboardNavDialog: () => JSX.Element;
  onTranscriptClick: (event: MouseEvent) => void;
  onTranscriptKeyDown: (event: KeyboardEventWithWistiaHandling) => void;
};

export const useTranscriptKeyboardNavigation = ({
  shadowRoot,
}: {
  shadowRoot: ShadowRoot;
}): UseTranscriptKeyboardNavigationReturnValue => {
  const { player } = useWistiaPlayerContext();
  const { isAutoScrollEnabled, mouseIsOverTranscript } = useTranscriptSignals();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [activeElementBeforeOpenDialog, setActiveElementBeforeOpenDialog] =
    useState<HTMLElement | null>(null);

  const closeDialog = useCallback(() => {
    dialogRef.current?.close();
    activeElementBeforeOpenDialog?.focus();
  }, [activeElementBeforeOpenDialog]);

  const openDialog = useCallback(() => {
    const { activeElement } = shadowRoot;
    if (activeElement instanceof HTMLElement) {
      setActiveElementBeforeOpenDialog(activeElement);
    }
    dialogRef.current?.show();
  }, [shadowRoot]);

  const onDocumentKeyDown = useCallback(
    (event: KeyboardEventWithWistiaHandling) => {
      if (!mouseIsOverTranscript.value || event._handledByWistia) {
        return;
      }
      switch (event.key) {
        case '?':
          if (dialogRef.current?.open) {
            closeDialog();
          } else {
            openDialog();
          }
          break;
        case 'Escape':
          closeDialog();
          break;
        default:
          break;
      }
      event._handledByWistia = true;
    },
    [activeElementBeforeOpenDialog],
  );

  useEffect(() => {
    document.addEventListener('keydown', onDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', onDocumentKeyDown);
    };
  }, [onDocumentKeyDown]);

  const onTranscriptKeyDown = useCallback(
    (event: KeyboardEventWithWistiaHandling) => {
      if (event._handledByWistia) {
        return;
      }

      const playerState = player.state();
      switch (event.key) {
        case '?':
          if (dialogRef.current?.open) {
            closeDialog();
          } else {
            openDialog();
          }
          break;
        case 'Escape':
          closeDialog();
          break;
        case 'a':
          isAutoScrollEnabled.value = !isAutoScrollEnabled.value;
          break;
        case 'p':
          if (playerState === 'beforeplay' || playerState === 'paused') {
            player.play();
          }
          if (playerState === 'playing') {
            player.pause();
          }
          if (playerState === 'ended') {
            player.time(0);
            player.play();
          }
          break;
        default:
          break;
      }

      event._handledByWistia = true;
    },
    [activeElementBeforeOpenDialog, player, shadowRoot],
  );

  const onTranscriptClick = useCallback(({ target }: PointerEvent) => {
    if (target instanceof HTMLElement && dialogRef.current?.contains(target)) {
      // Don't close the dialog if the click was inside the dialog
      return;
    }

    if (dialogRef.current?.open) {
      dialogRef.current.close();
    }
  }, []);

  const MemoizeKeyboardNavDialog = useMemo(
    () => () => {
      return <KeyboardNavDialog ref={dialogRef} onClickClose={closeDialog} />;
    },
    [],
  );

  return {
    onTranscriptKeyDown,
    onTranscriptClick,
    KeyboardNavDialog: MemoizeKeyboardNavDialog,
  };
};
