import { Fragment, h, JSX } from 'preact';
import { isNil } from '@wistia/type-guards';
import { useEffect, useState } from 'preact/hooks';
import { addHashToHex, Color } from '../../../utilities/color.js';
import { TIMED_WORD_STYLES } from './TimedWord.tsx';
import { CHAPTER_STYLES } from './Chapter.tsx';
import {
  TRANSCRIPT_DOCUMENT_CONTAINER_STYLES,
  TRANSCRIPT_SCROLL_CONTAINER_STYLES,
} from '../Transcript.tsx';
import { useCustomAndGoogleFonts } from '../../shared/hooks/useGoogleAndCustomFonts.ts';
import { AUTO_SCROLL_TOGGLE_STYLES } from './AutoScrollToggle.tsx';
import { EXTENDED_AUDIO_DESCRIPTION_CUE_STYLES } from './ExtendedAudioDescriptionCue.tsx';
import { PARAGRAPH_STYLES } from './Paragraph.tsx';
import { HOVER_BUTTON_STYLES } from './HoverButtons/HoverButton.tsx';
import { KEYBOARD_NAV_DIALOG_STYLES } from './KeyboardNavDialog.tsx';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';
import { HOVER_BUTTONS_CONTAINER_STYLES } from './HoverButtons/HoverButtonsContainer.tsx';
import { useWistiaPlayerContext } from '../hooks/useWistiaPlayerContext.tsx';
import { CUE_STYLES } from './Cue.tsx';
import { DOWNLOAD_BUTTON_STYLES } from './DownloadButton.tsx';

const WISTIA_BLUE_600 = '#1b7fde';

const ALPHA = 0.85;

type TranscriptStylesProps = {
  accentColor?: string | null;
  fontFamily: string;
  hasGoogleFont: boolean;
};

const GoogleFontLink = ({ fontFamily }: { fontFamily: string }): JSX.Element => {
  useCustomAndGoogleFonts(isNil(fontFamily) ? [] : [{ fontFamily }]);
  return <Fragment />;
};

const typedAddHashToHex = addHashToHex as unknown as (color: string) => string;

export const TranscriptStyles = ({
  accentColor: accentColorMaybe,
  fontFamily,
  hasGoogleFont,
}: TranscriptStylesProps): JSX.Element => {
  const { autoScrollMarginTopPx } = useTranscriptContext();
  const { player } = useWistiaPlayerContext();
  const [playerColor, setPlayerColor] = useState<string | null>(null);
  const [accentColor, setAccentColor] = useState<string>(accentColorMaybe ?? WISTIA_BLUE_600);

  useEffect(() => {
    if (isNil(player) || accentColorMaybe != null) {
      return undefined;
    }

    player.ready(() => {
      const initialColor = player.playerColor();

      // set player color to player's initial color on load
      if (initialColor) {
        setPlayerColor(typedAddHashToHex(initialColor));
      }
    });

    // bind returns its own unbind function
    return player.bind('colorchange', (color: string) => {
      setPlayerColor(typedAddHashToHex(color));
    });
  }, [player, accentColorMaybe]);

  useEffect(() => {
    if (accentColorMaybe === '') {
      setAccentColor(playerColor ?? WISTIA_BLUE_600);
      return;
    }
    setAccentColor(accentColorMaybe ?? playerColor ?? WISTIA_BLUE_600);
  }, [accentColorMaybe, playerColor]);

  const baseColor = new Color(accentColor);
  const baseColorIsLight = baseColor.isLight();

  // We have to assert that the output of alpha is a Color because the inferred
  // type is a string OR a Color, since alpha is a mixed getter/setter
  const currentWordBackgroundColor = (baseColor.alpha(ALPHA) as Color).toRgba();
  const timedWordHoverBackgroundColor = new Color(currentWordBackgroundColor)
    .lighten('10%')
    .toRgba();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const lighteningPercentage = baseColorIsLight ? 15 : 40;
  const cueColor = new Color(accentColor).lighten(`${lighteningPercentage}%`).toRgba();

  const accentColorHex = new Color(accentColor).toHex();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const accentColorHexWithHash = addHashToHex(accentColorHex);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const audioDescriptionCueColor = addHashToHex(
    new Color(accentColor).lighten(`${lighteningPercentage}%`).toHex(),
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const audioDescriptionCueHoverColor = addHashToHex(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    new Color(accentColor).lighten(`${lighteningPercentage * 0.8}%`).toHex(),
  );

  return (
    <Fragment>
      {hasGoogleFont ? <GoogleFontLink fontFamily={fontFamily} /> : null}
      <style>
        {`
        :host {
          display: block;
          --font-family: ${fontFamily};
          --cue-color: ${cueColor};
          --cue-text-color: ${baseColorIsLight ? 'black' : 'white'};
          --current-word-text-color: ${baseColorIsLight ? 'black' : 'white'};
          --current-word-background-color: ${currentWordBackgroundColor};
          --timed-word-hover-background-color: ${timedWordHoverBackgroundColor};
          --accent-color: ${accentColorHexWithHash};
          --focus-ring-color: ${timedWordHoverBackgroundColor};
          --ead-background-color: ${
            audioDescriptionCueColor === '#ffffff' ? cueColor : audioDescriptionCueColor
          };
          --ead-hover-background-color: ${
            audioDescriptionCueHoverColor === '#ffffff'
              ? timedWordHoverBackgroundColor
              : audioDescriptionCueHoverColor
          };
          --auto-scroll-margin-top: ${autoScrollMarginTopPx}px;
        }

        .app-root {
          height: 100%;
          position: relative;
          /* We do this transform: scale(1) just to create a new stacking
          context to contain the effect of z-indexes to within the wistia-transcript */
          transform: scale(1);
        }
      `}

        {TIMED_WORD_STYLES}
        {CUE_STYLES}
        {CHAPTER_STYLES}
        {TRANSCRIPT_DOCUMENT_CONTAINER_STYLES}
        {TRANSCRIPT_SCROLL_CONTAINER_STYLES}
        {AUTO_SCROLL_TOGGLE_STYLES}
        {EXTENDED_AUDIO_DESCRIPTION_CUE_STYLES}
        {PARAGRAPH_STYLES}
        {HOVER_BUTTON_STYLES}
        {HOVER_BUTTONS_CONTAINER_STYLES}
        {DOWNLOAD_BUTTON_STYLES}
        {KEYBOARD_NAV_DIALOG_STYLES}
      </style>
    </Fragment>
  );
};
