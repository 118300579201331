import { h, JSX } from 'preact';
import { isNil, Nullable } from '@wistia/type-guards';
import { theWordChapterTranslatedTo } from '../utilities/chapterTranslations.ts';
import { useWistiaPlayerContext } from '../hooks/useWistiaPlayerContext.tsx';
import { ChapterIcon } from './icons/ChapterIcon.tsx';
import { countMetric } from '../../../utilities/simpleMetrics.js';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';
import { usePlayerCaptionsLanguage } from '../hooks/usePlayerCaptionsLanguage.tsx';
import { useAutoEnableAutoScroll } from '../hooks/useAutoEnableAutoScroll.ts';

export const CHAPTER_STYLES = `
  .chapter {
    align-items: start;
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: inherit;
    gap: 8px;
    outline: none;
    padding: 0;
    padding: 8px;
    transition: box-shadow 0.1s, background-color 0.1s;
  }

  .chapter:hover {
    background-color: #ebebed;
  }

  .chapter:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-color);
    background-color: #ebebed;
  }

  .topline {
    color: #7b7b87;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 4px;
  }

  .chapter-text {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    text-transform: uppercase;
    text-align: left;
  }

  .chapter-title {
    color: #37373c;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.14px;
    line-height: 1.3;
    margin: 0;
  }
`;

type ChapterProps = {
  chapterTitle?: string;
  id: string;
  time?: number;
};

export const Chapter = ({ chapterTitle, time }: ChapterProps): Nullable<JSX.Element> => {
  const { player } = useWistiaPlayerContext();
  const { playerCaptionsLanguage } = usePlayerCaptionsLanguage();
  const { mediaHashedId } = useTranscriptContext();
  const autoEnableAutoScroll = useAutoEnableAutoScroll();

  const onClickChapter = () => {
    if (isNil(time)) {
      return;
    }

    player.time(time / 1000);
    autoEnableAutoScroll();
    if (player.state() === 'beforeplay') {
      player.play();
    }

    countMetric('transcript/seek-by-chapter', 1, {
      mediaHashedId,
    });
  };

  if (isNil(chapterTitle)) {
    return null;
  }

  return (
    <button part="chapter" className="chapter" onClick={onClickChapter} type="button" tabIndex={0}>
      <div part="chapter-header" className="topline">
        <ChapterIcon />
        <span className="chapter-text">{theWordChapterTranslatedTo(playerCaptionsLanguage)}</span>
      </div>
      <span part="chapter-title" className="chapter-title">
        {chapterTitle}
      </span>
    </button>
  );
};
