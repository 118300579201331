import { h, JSX } from 'preact';

export const ChapterIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H12C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4ZM7.23235 5C7.23235 4.44772 7.68007 4 8.23235 4H11.2324C11.7846 4 12.2324 4.44772 12.2324 5C12.2324 5.55228 11.7846 6 11.2324 6H8.23235C7.68007 6 7.23235 5.55228 7.23235 5ZM8.23235 7C7.68007 7 7.23235 7.44772 7.23235 8C7.23235 8.55228 7.68007 9 8.23235 9H11.2324C11.7846 9 12.2324 8.55228 12.2324 8C12.2324 7.44772 11.7846 7 11.2324 7H8.23235ZM7.23235 11C7.23235 10.4477 7.68007 10 8.23235 10H11.2324C11.7846 10 12.2324 10.4477 12.2324 11C12.2324 11.5523 11.7846 12 11.2324 12H8.23235C7.68007 12 7.23235 11.5523 7.23235 11ZM5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4ZM4 8C4 7.44772 4.44772 7 5 7C5.55228 7 6 7.44772 6 8C6 8.55228 5.55228 9 5 9C4.44772 9 4 8.55228 4 8ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12C5.55228 12 6 11.5523 6 11C6 10.4477 5.55228 10 5 10Z"
      />
    </svg>
  );
};
