import { ComponentChildren, createContext, h, JSX } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { TranscriptProvidersProps } from '../components/TranscriptProviders.tsx';

type TranscriptContextType = {
  autoScrollMarginTopPx: number;
  hasAutoScrollControl: boolean;
  hasDownloadControl: boolean;
  mediaHashedId: string;
  setTranscriptScrollContainer: (element: HTMLElement) => void;
  transcriptScrollContainer: HTMLElement | null;
};

export const TranscriptContext = createContext<TranscriptContextType | null>(null);

type TranscriptContextProviderProps = {
  autoScrollMarginTopPx: TranscriptProvidersProps['autoScrollMarginTopPx'];
  children: ComponentChildren;
  hasAutoScrollControl: boolean;
  hasDownloadControl: boolean;
  mediaHashedId: string;
};

export const TranscriptContextProvider = ({
  children,
  autoScrollMarginTopPx,
  mediaHashedId,
  hasAutoScrollControl,
  hasDownloadControl,
}: TranscriptContextProviderProps): JSX.Element => {
  const [transcriptScrollContainer, setTranscriptScrollContainer] = useState<HTMLElement | null>(
    null,
  );

  const value = {
    mediaHashedId,
    setTranscriptScrollContainer,
    transcriptScrollContainer,
    autoScrollMarginTopPx,
    hasAutoScrollControl,
    hasDownloadControl,
  };

  return <TranscriptContext.Provider value={value}>{children}</TranscriptContext.Provider>;
};

export const useTranscriptContext = (): TranscriptContextType => {
  const context = useContext(TranscriptContext);
  if (context === null) {
    throw new Error('useTranscriptContext must be used within a TranscriptProvider');
  }
  return context;
};
