import { h, JSX } from 'preact';
import { useWistiaPlayerContext } from '../hooks/useWistiaPlayerContext.tsx';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';
import { countMetric } from '../../../utilities/simpleMetrics.js';
import { useAutoEnableAutoScroll } from '../hooks/useAutoEnableAutoScroll.ts';
import { ExtendedAudioDescriptionControl } from '../types.ts';

export const EXTENDED_AUDIO_DESCRIPTION_CUE_STYLES = `
  .extended-audio-description-cue {
    appearance: none;
    background-color: var(--ead-background-color);
    border-radius: 4px;
    border: none;
    color: var(--cue-text-color);
    cursor: pointer;
    line-height: 1.7;
    outline: none;
    padding: 8px 12px;
    text-align: left;
    transition: box-shadow 0.1s, background-color 0.1s;
  }

  .extended-audio-description-cue:hover {
    background-color: var(--ead-hover-background-color);
  }

  .extended-audio-description-cue:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-color);
  }
`;

type ExtendedAudioDescriptionCueProps = {
  startTimeMs: number;
  text: string;
};

export const ExtendedAudioDescriptionCue = ({
  text,
  startTimeMs,
}: ExtendedAudioDescriptionCueProps): JSX.Element | null => {
  const { player } = useWistiaPlayerContext();
  const { mediaHashedId } = useTranscriptContext();
  const autoEnableAutoScroll = useAutoEnableAutoScroll();

  const handleClick = () => {
    const eadControl = player._impl?.controls.extendedAudioDescriptionButton as
      | ExtendedAudioDescriptionControl
      | undefined;

    // If we click on a cue, we should interrupt any other cues or words. This
    // also ensures we start playing the cue immediately instead of entering a
    // race condition where audio may play from the video briefly before the cue
    // starts.
    if (eadControl && (player.state() === 'playing' || eadControl.isAudioPlaying())) {
      const cue = eadControl.findCueForTime(startTimeMs / 1000);
      if (cue) {
        eadControl.playCue(cue);
      }
    }

    player.time(startTimeMs / 1000);
    autoEnableAutoScroll();

    countMetric('transcript/seek-by-ead-cue', 1, {
      mediaHashedId,
    });
  };

  return (
    <button
      type="button"
      className="extended-audio-description-cue"
      part="extended-audio-description-cue"
      onClick={handleClick}
      tabIndex={0}
    >
      <span
        className="extended-audio-description-cue-text"
        part="extended-audio-description-cue-text"
      >
        {text}
      </span>
    </button>
  );
};
