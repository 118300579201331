import { ComponentChildren, h, JSX } from 'preact';

type HoverButtonyLabelProps = {
  children: ComponentChildren;
};

export const HoverButtonyLabel = ({ children }: HoverButtonyLabelProps): JSX.Element => {
  return (
    <label class="hover-button" part="hover-button">
      {children}
    </label>
  );
};
