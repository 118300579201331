import { useEffect } from 'preact/hooks';
import { instanceOfHTMLElement } from '../utilities/domUtils.ts';
import { CurrentWordAndCueTrackerData } from './useCurrentWordAndCueTrackerData.ts';
import { useTranscriptContext } from './useTranscriptContext.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

export const useSetCurrentWordSignal = ({
  currentWordStartTime,
}: Pick<CurrentWordAndCueTrackerData, 'currentWordStartTime'>): void => {
  const { transcriptScrollContainer } = useTranscriptContext();
  const { currentWordElement } = useTranscriptSignals();

  useEffect(() => {
    if (currentWordStartTime == null) {
      currentWordElement.value = null;
      return;
    }
    const currentWordElem = transcriptScrollContainer?.querySelector(
      `[data-start-time="${currentWordStartTime}"]`,
    );

    if (instanceOfHTMLElement(currentWordElem)) {
      currentWordElement.value = currentWordElem;
    }
  }, [currentWordStartTime]);
};
