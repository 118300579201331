import { h, JSX } from 'preact';
import { HTMLAttributes } from 'preact/compat';

export const HOVER_BUTTON_STYLES = `
  .hover-button {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--font-family);
    font-size: .85rem;
    gap: 8px;
    justify-content: center;
    padding: 4px 8px;
    transition: background-color 0.1s;
    user-select: none;
  }

  .hover-button:hover {
    background-color: #303030;
  }

  .hover-button:focus-visible {
    outline: none;
    background-color: #303030;
    box-shadow: inset 0 0 0 2px white, 0 0 0 2px #303030;
  }
`;

type HoverButtonProps = Omit<HTMLAttributes<HTMLButtonElement>, 'className'>;

export const HoverButton = (props: HoverButtonProps): JSX.Element => {
  return <button type="button" part="hover-button" class="hover-button" tabIndex={0} {...props} />;
};
