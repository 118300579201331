import { useEffect } from 'preact/hooks';
import { isNil } from '@wistia/type-guards';
import { useSignalEffect } from '@preact/signals';
import {
  isCurrentWordVisibleInScrollRegion,
  MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL,
  MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL,
  maybeAutoScroll,
} from '../utilities/autoScrollUtilities.ts';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { useTranscriptContext } from './useTranscriptContext.tsx';
import { useScrollStrategy } from './useScrollStrategy.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

/* The behaviors of autoscroll are nuanced. We have not yet accounted for all of
the nuance in automated tests, which isn't great, but we can at least document
the intended behaviors here. They are as follows, in no particular order:

1. Autoscroll is enabled by default.
2. Autoscroll can be disabled by the user either by clicking the autoscroll
   toggle, or manually scrolling such that the current word in the transcript
   becomes completely outside of the top or bottom of the visible portion of the
   transcript.
3. The transcript should scroll to the current word _instantly_ when the
   transcript is first loaded (as it may be far down if the video is resumable
   and resuming at non-zero time), or when autoscroll is re-enabled.
4. Autoscroll should _not_ be disabled automatically in that brief moment during
   which, if the video is resumable, the current word may initially be outside
   of the visible portion of the transcript document.
5. The transcript should scroll to the current word _smoothly_ in all cases
   besides scrolling to the initial current word, or re-enabling autoscroll.
6. Autoscroll occurs when the current word becomes _near_ outside of the visible
   portion of the document, or of course completely outside of it. This may
   happen during the normal course of playback, or via seeking in the playbar.
   We must allow for the possibility that even during normal playback, the word
   that becomes the current word may be completely outside the visible portion
   of the transcript document, say if a Chapter indicator (which is relatively
   big) was sitting at the bottom, and we're jumping to the word following it.
7. Autoscroll should never ever scrolljack the user. That is, if the user is
   manually scrolling, autoscroll should not interfere with that.
8. Autoscroll is confined to the transcript document container. It should not
   ever scroll the entire page.
*/

export const useAutoScroll = (): void => {
  const {
    isAutoScrollEnabled,
    isAutoScrollingNow,
    isManuallyScrollingNow,
    playerState,
    shouldNextAutoScrollBeInstant,
    currentWordElement,
  } = useTranscriptSignals();
  const { player } = useWistiaPlayerContext();
  const { transcriptScrollContainer, autoScrollMarginTopPx } = useTranscriptContext();
  const { scrollStrategy } = useScrollStrategy();

  useEffect(() => {
    const unbind = player.bind('seek', () => {
      shouldNextAutoScrollBeInstant.value = true;
    });

    return unbind;
  }, [player]);

  useSignalEffect(() => {
    const currentWordAtLeastPartiallyVisible = isCurrentWordVisibleInScrollRegion({
      marginTop: autoScrollMarginTopPx + MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL,
      marginBottom: MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL,
      scrollStrategy,
      transcriptScrollContainer,
      currentWordElement: currentWordElement.value,
    });

    if (currentWordAtLeastPartiallyVisible) {
      shouldNextAutoScrollBeInstant.value = false;
    }
  });

  let pseudoScrollEndTimeout = setTimeout(() => undefined, 0);

  useEffect(() => {
    if (isNil(transcriptScrollContainer)) {
      return undefined;
    }

    const onScrollWithinTranscript = (event: Event) => {
      if (!isAutoScrollingNow.value) {
        isManuallyScrollingNow.value = true;
      }

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const currentWordIsSafelyWithinVisiblePartOfTranscript = isCurrentWordVisibleInScrollRegion({
        marginTop: MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL + autoScrollMarginTopPx,
        marginBottom: MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL,
        scrollStrategy,
        transcriptScrollContainer,
        currentWordElement: currentWordElement.value,
      });

      if (!currentWordIsSafelyWithinVisiblePartOfTranscript && !isAutoScrollingNow.value) {
        isAutoScrollEnabled.value = false;
      }

      // We do this weird pseudo-scrollend thing instead of using the real
      // scrollend event because somehow when developing this, scrollend was
      // firing before scrollstart when re-enabling autoscroll and scrolling
      // instantly back to the current word, which made our
      // isManuallyScrollingNow signal get stuck to true. This is a workaround
      // for that.
      clearTimeout(pseudoScrollEndTimeout);
      pseudoScrollEndTimeout = setTimeout(() => {
        isAutoScrollingNow.value = false;
        isManuallyScrollingNow.value = false;
      }, 100);
    };

    transcriptScrollContainer.addEventListener('scroll', onScrollWithinTranscript);

    return () => {
      transcriptScrollContainer.removeEventListener('scroll', onScrollWithinTranscript);
    };
  }, [
    transcriptScrollContainer,
    autoScrollMarginTopPx,
    isAutoScrollEnabled.value,
    isAutoScrollingNow.value,
    isManuallyScrollingNow.value,
    scrollStrategy,
  ]);

  useEffect(() => {
    const onScrollWindow = () => {
      if (!isAutoScrollingNow.value) {
        isManuallyScrollingNow.value = true;
      }

      const currentWordIsSafelyWithinVisiblePartOfTranscript = isCurrentWordVisibleInScrollRegion({
        marginTop: MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL + autoScrollMarginTopPx,
        marginBottom: MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL,
        scrollStrategy,
        transcriptScrollContainer,
        currentWordElement: currentWordElement.value,
      });

      if (!currentWordIsSafelyWithinVisiblePartOfTranscript && !isAutoScrollingNow.value) {
        isAutoScrollEnabled.value = false;
      }

      // We do this weird pseudo-scrollend thing instead of using the real
      // scrollend event because somehow when developing this, scrollend was
      // firing before scrollstart when re-enabling autoscroll and scrolling
      // instantly back to the current word, which made our
      // isManuallyScrollingNow signal get stuck to true. This is a workaround
      // for that.
      clearTimeout(pseudoScrollEndTimeout);
      pseudoScrollEndTimeout = setTimeout(() => {
        isAutoScrollingNow.value = false;
        isManuallyScrollingNow.value = false;
      }, 100);
    };

    if (scrollStrategy === 'whole-page') {
      window.addEventListener('scroll', onScrollWindow);
    }

    return () => {
      window.removeEventListener('scroll', onScrollWindow);
    };
  }, [
    scrollStrategy,
    transcriptScrollContainer,
    isManuallyScrollingNow.value,
    autoScrollMarginTopPx,
    isAutoScrollEnabled.value,
    isAutoScrollingNow.value,
  ]);

  useSignalEffect(() => {
    maybeAutoScroll({
      behavior: shouldNextAutoScrollBeInstant.value ? 'instant' : 'smooth',
      scrollStrategy,
      transcriptScrollContainer,
      autoScrollMarginTopPx,
      playerState: playerState.value,
      onAutoScroll: () => {
        isAutoScrollingNow.value = true;
        shouldNextAutoScrollBeInstant.value = false;
      },
      currentWordElement: currentWordElement.value,
      isAutoScrollEnabled: isAutoScrollEnabled.value,
      isManuallyScrollingNow: isManuallyScrollingNow.value,
      shouldNextAutoScrollBeInstant: shouldNextAutoScrollBeInstant.value,
    });
  });
};
