import { ComponentChildren, Fragment, h, JSX } from 'preact';
import { Z_INDEXES } from '../../utilities/constants.ts';
import { useScrollStrategy } from '../../hooks/useScrollStrategy.tsx';

export const HOVER_BUTTONS_CONTAINER_STYLES = `
.app-root:focus-within .hover-buttons-container,
  .app-root:hover .hover-buttons-container {
    opacity: 1;
  }

  .hover-buttons-container {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
    z-index: ${Z_INDEXES.HOVER_BUTTONS_CONTAINER};
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    opacity: 0;
    transition: opacity 0.1s;
    height: 28px;
  }

  .hover-buttons-sticky-container {
    position: sticky;
    top: var(--auto-scroll-margin-top);
    right: 0;
    z-index: ${Z_INDEXES.HOVER_BUTTONS_STICKY_CONTAINER};
  }`;

export const HoverButtonsContainer = ({
  children,
}: {
  children: ComponentChildren;
}): JSX.Element => {
  const { scrollStrategy } = useScrollStrategy();

  return (
    <Fragment>
      {scrollStrategy === 'whole-page' ? (
        <div className="hover-buttons-sticky-container">
          <div className="hover-buttons-container">{children}</div>
        </div>
      ) : (
        <div className="hover-buttons-container">{children}</div>
      )}
    </Fragment>
  );
};
