import { ComponentChildren, createContext, h, JSX } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { CaptionsControl } from '../types.ts';

type PlayerCaptionsLanguageContextType = {
  playerCaptionsLanguage: string | undefined;
};

const PlayerCaptionsLanguageContext = createContext<PlayerCaptionsLanguageContextType | null>(null);

export const PlayerCaptionsLanguageProvider = ({
  children,
}: {
  children: ComponentChildren;
}): JSX.Element => {
  const { player } = useWistiaPlayerContext();
  const [playerCaptionsLanguage, setPlayerCaptionsLanguage] = useState<string | undefined>(
    (player._impl?.controls.captionsButton as CaptionsControl | undefined)?.selectedLanguage,
  );

  useEffect(() => {
    const captionsButtonControl = player._impl?.controls.captionsButton as
      | CaptionsControl
      | undefined;

    const initialLanguage = captionsButtonControl?.selectedLanguage;
    if (initialLanguage !== '_off_') {
      setPlayerCaptionsLanguage(initialLanguage === '_off_' ? undefined : initialLanguage);
    }

    const onAfterReplace = () => {
      setPlayerCaptionsLanguage(undefined);
    };
    player.bind('afterreplace', onAfterReplace);

    const onCaptionsLanguageChange = (language: string) => {
      setPlayerCaptionsLanguage(language === '_off_' ? undefined : language);
    };
    player.bind('captionslanguagechange', onCaptionsLanguageChange);

    return () => {
      player.unbind('afterreplace', onAfterReplace);
      player.unbind('captionslanguagechange', onCaptionsLanguageChange);
    };
  }, [player]);

  const value = {
    playerCaptionsLanguage,
  };

  return (
    <PlayerCaptionsLanguageContext.Provider value={value}>
      {children}
    </PlayerCaptionsLanguageContext.Provider>
  );
};

export const usePlayerCaptionsLanguage = (): PlayerCaptionsLanguageContextType => {
  const context = useContext(PlayerCaptionsLanguageContext);
  if (context === null) {
    throw new Error(
      'usePlayerCaptionsLanguage must be used within a PlayerCaptionsLanguageProvider',
    );
  }
  return context;
};
