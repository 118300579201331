import { ComponentChildren, h, JSX } from 'preact';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';

type FocusScrollManagerProps = {
  children: ComponentChildren;
};

export const FocusScrollManager = ({ children }: FocusScrollManagerProps): JSX.Element => {
  const { autoScrollMarginTopPx } = useTranscriptContext();

  const onFocusIn = ({ target }: FocusEvent) => {
    // By default, the browser already scrolls elements into view when they
    // recieve focus and they're out of the viewport. The problem is, sometimes
    // even if the thing receiving focus in the trancript is within the
    // viewport, it may be obscured by something that's covering part of the
    // transcript (as is the case on the anonymous media page in Wistia - the
    // player is sticky at the top of the viewport, in front of the top of the
    // transcript). So here we use the transcript's autoScrollMarginTopPx to
    // determine if the newly focused element is probably not actually visible
    // and then we scroll it to the center of the viewport, where it will
    // _probably_ be visible. This isn't a perfect, catch-all solution, but it
    // at least works for the anonymous media page.

    if (!(target instanceof HTMLElement)) {
      return;
    }

    if (target.getBoundingClientRect().top < autoScrollMarginTopPx) {
      target.scrollIntoView({ block: 'center' });
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr)',
      }}
      onFocusIn={onFocusIn}
    >
      {children}
    </div>
  );
};
