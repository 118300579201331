import { useEffect } from 'preact/hooks';
import { isEmptyString, isNil, Nilable } from '@wistia/type-guards';
import { Font } from '../../../types/font.ts';
import { loadCustomFont } from '../../../utilities/fonts.ts';

export const useLoadFont = (font: Nilable<Font>): void => {
  useEffect(() => {
    if (isNil(font)) {
      return;
    }

    const { family, url } = font;

    if (isNil(url) || isEmptyString(url)) {
      return;
    }

    void loadCustomFont(family, url);
  }, [font]);
};
