import { h, render, Component } from 'preact';

class DownloadSVG extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg viewBox="0 0 19.4 15.4" style={this.props.style || {}} aria-hidden="true">
        <g
          fill="none"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        >
          <polyline points="0.8,6.2 0.8,14.7 18.6,14.7 18.6,6.2  "></polyline>
          <polyline points="13.5,7.3 9.7,11.1 5.9,7.3  "></polyline>
          <line x1="9.7" y1="11.1" x2="9.7" y2="0.8"></line>
        </g>
      </svg>
    );
  }
}

export default DownloadSVG;
