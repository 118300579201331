import { Nilable } from '@wistia/type-guards';

export const addPart = (element: Nilable<Element>, partName: string): void => {
  if (element == null) {
    return;
  }
  const parts = element.getAttribute('part')?.split(' ') ?? [];
  if (!parts.includes(partName)) {
    parts.push(partName);
    element.setAttribute('part', parts.join(' '));
  }
};

export const removePart = (element: Nilable<Element>, partName: string): void => {
  if (element == null) {
    return;
  }
  const parts = element.getAttribute('part')?.split(' ') ?? [];
  const updatedParts = parts.filter((part) => part !== partName);
  element.setAttribute('part', updatedParts.join(' '));
};

export const instanceOfHTMLElement = (
  node: Element | Node | null | undefined,
): node is HTMLElement => {
  return node instanceof HTMLElement;
};
