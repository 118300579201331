import { h, JSX } from 'preact';
import { useCallback } from 'preact/hooks';
import { Nilable } from '@wistia/type-guards';
import DownloadSVG from '../../media/players/vulcanV2Player/shared/ui_components/svgs/DownloadSVG.jsx';
import { eV1Protocol, mediaDataHost } from '../../../utilities/hosts.js';
import { HoverButton } from './HoverButtons/HoverButton.tsx';

type DownloadButtonProps = {
  embedHost: Nilable<string>;
  mediaHashedId: string;
};

export const DOWNLOAD_BUTTON_STYLES = `
  .download-icon-container g {
    stroke: currentColor;
  }
`;

export const DownloadButton = ({ mediaHashedId, embedHost }: DownloadButtonProps): JSX.Element => {
  const downloadUrl = `${eV1Protocol()}//${mediaDataHost({
    embedHost,
  })}/embed/transcripts/${mediaHashedId}.html`;

  const onClickDownload = useCallback(() => {
    window.open(downloadUrl, '_blank');
  }, []);

  return (
    <HoverButton
      aria-label="Download transcript"
      onClick={onClickDownload}
      style={{ width: '32px' }}
      title="Download transcript"
    >
      <div className="download-icon-container">
        <DownloadSVG style={{ width: '100%' }} />
      </div>
    </HoverButton>
  );
};
