import { ComponentProps, Fragment, h, JSX } from 'preact';
import { forwardRef } from 'preact/compat';

// SwitchProps should take a bunch of props from those of an html input element of type checkbox
type SwitchProps = Pick<ComponentProps<'input'>, 'className' | 'id' | 'name' | 'part'> & {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ id, name, checked, onCheckedChange, part, className }, ref): JSX.Element => {
    return (
      <Fragment>
        <button
          ref={ref}
          type="button"
          onClick={() => onCheckedChange(!checked)}
          part={part}
          className={className}
          tabIndex={0}
        >
          <span className="switch-knob" />
          <input
            aria-hidden="true"
            tabIndex={-1}
            type="checkbox"
            id={id}
            name={name}
            defaultChecked={checked}
            onChange={(event) => {
              if (!(event.target instanceof HTMLInputElement)) {
                // Making TS happy
                return;
              }
              onCheckedChange(event.target.checked);
            }}
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              opacity: 0,
              margin: 0,
            }}
          />
        </button>
      </Fragment>
    );
  },
);
