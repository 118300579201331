import { useEffect, useState } from 'preact/hooks';
import { Nilable } from '@wistia/type-guards';
import { TranscriptApi } from '../utilities/TranscriptApi.ts';
import { CaptionsData, TranscriptDocument } from '../types.ts';

type UseTranscriptDocumentResponse = {
  captionsData: CaptionsData;
  transcriptDocument: TranscriptDocument;
};

export const useTranscriptData = ({
  audioDescriptionIetfLanguageTag,
  embedHost,
  transcriptIetfLanguageTag,
  mediaHashedId,
}: {
  audioDescriptionIetfLanguageTag?: string;
  embedHost?: Nilable<string>;
  mediaHashedId: string;
  transcriptIetfLanguageTag?: string;
}): UseTranscriptDocumentResponse => {
  const [transcriptDocument, setTranscriptDocument] = useState<TranscriptDocument | null>(null);
  const [captionsData, setCaptionsData] = useState<CaptionsData>([]);

  useEffect(() => {
    const getTranscriptData = async () => {
      const transcriptApi = new TranscriptApi(embedHost);
      const transcriptData = await transcriptApi.get({
        mediaHashedId,
        transcriptIetfLanguageTag,
        audioDescriptionIetfLanguageTag,
      });
      setTranscriptDocument(transcriptData.document);
      setCaptionsData(transcriptData.captions_data);
    };
    void getTranscriptData();
  }, [mediaHashedId, transcriptIetfLanguageTag, audioDescriptionIetfLanguageTag, embedHost]);

  return {
    transcriptDocument: transcriptDocument ?? [],
    captionsData,
  };
};
