export const Z_INDEXES = {
  TIMED_WORD: 0,
  CURRENT_WORD: 1,
  HOVERED_TIMED_WORD: 2,
  HOVER_BUTTONS_STICKY_CONTAINER: 3,
  HOVER_BUTTONS_CONTAINER: 3,
  KEYBOARD_NAV_DIALOG: 3,
};

export const CUE_CLASSNAME = 'cue';
