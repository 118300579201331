import { useEffect } from 'preact/hooks';
import { addPart, removePart } from '../utilities/domUtils.ts';
import { useTranscriptContext } from './useTranscriptContext.tsx';
import { CUE_CLASSNAME } from '../utilities/constants.ts';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

export const useCurrentCueDOMManipulator = ({
  currentCueStartTimeMs,
}: {
  currentCueStartTimeMs: number | undefined;
}): void => {
  const { transcriptScrollContainer } = useTranscriptContext();
  const { shouldHighlight } = useTranscriptSignals();

  useEffect(() => {
    if (!shouldHighlight.value) {
      return;
    }

    const oldCurrentCueElems = transcriptScrollContainer?.querySelectorAll('.current-cue');
    const newCurrentCueElems = transcriptScrollContainer?.querySelectorAll(
      `.${CUE_CLASSNAME}[data-start-time-ms="${currentCueStartTimeMs}"]`,
    );

    if (oldCurrentCueElems) {
      oldCurrentCueElems.forEach((elem) => {
        elem.classList.remove('current-cue');
        removePart(elem, 'current-cue');
      });
    }

    if (newCurrentCueElems) {
      newCurrentCueElems.forEach((elem) => {
        elem.classList.add('current-cue');
        addPart(elem, 'current-cue');
      });
    }
  }, [currentCueStartTimeMs, shouldHighlight.value]);
};
