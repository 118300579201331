import { useEffect, useState } from 'preact/hooks';
import { useCurrentPlayerTime } from '../../shared/hooks/useCurrentPlayerTime.ts';
import { PlayerState } from '../../../types/player-api-types.ts';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

export const useSetShouldHighlightSignal = (): void => {
  const { player } = useWistiaPlayerContext();
  const timeFromPlayerMs = useCurrentPlayerTime(player);
  const { shouldHighlight } = useTranscriptSignals();
  const [playerState, setPlayerState] = useState<PlayerState>('beforeplay');

  useEffect(() => {
    // bind returns its own unbind function
    return player.bind('statechange', (state: PlayerState) => {
      setPlayerState(state);
    });
  }, [playerState]);

  useEffect(() => {
    shouldHighlight.value = playerState !== 'beforeplay' || (timeFromPlayerMs ?? 0) > 0;
  }, [timeFromPlayerMs, playerState]);
};
