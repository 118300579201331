const CHAPTER_TRANSLATIONS: Record<string, string | undefined> = {
  aar: 'Maqaallo',
  abk: 'Литература',
  ace: 'Bab',
  ach: 'Obusuubuzi',
  ada: 'Ntsia',
  ady: 'Литератур',
  afa: 'اوراق',
  afh: 'Skriuwe',
  afr: 'Hoofstuk',
  ain: 'Tupci',
  aka: 'Akenkan',
  akk: 'Kapitu',
  alb: 'Kapitulli',
  ale: "K'it'an",
  alg: 'Tawaxkinini',
  alt: 'Бүлгэ',
  amh: 'ክፍል',
  anp: 'अध्याय',
  apa: 'Natecihoyan',
  ara: 'فصل',
  arg: 'Capítulu',
  arm: 'գլխում',
  arn: 'Küme',
  arp: 'Oda:ši',
  art: 'Kapitolo',
  arw: 'Ashkitu',
  asm: 'অধ্যায়',
  ast: 'Capítulu',
  ath: 'Pejíw',
  aus: 'Karnta',
  ava: 'Кептар',
  ave: 'Āγvan',
  awa: 'पण्ड',
  aym: 'Noka',
  aze: 'Bölmə',
  bad: 'Agbi',
  bai: 'Zug',
  bak: 'Глава',
  bal: 'باب',
  bam: 'Sawuri',
  ban: 'Bab',
  baq: 'Kapitulua',
  bas: 'অধ্যাৱসায়ী',
  bat: 'Jańǧa',
  bej: 'Zwaldu',
  bel: 'Глава',
  bem: 'Akantu',
  ben: 'অধ্যায়',
  ber: 'Tasfift',
  bho: 'अध्याय',
  bih: 'पाण्ड',
  bik: 'Kasaysayan',
  bin: 'Kapitu',
  bis: 'Lōta',
  bla: 'Kawehkamepita',
  bnt: 'Igika',
  bos: 'Poglavlje',
  bra: 'Glan',
  bre: 'Kemenn',
  bsl: 'Be-ay',
  btk: 'Bab',
  bua: 'Майал',
  bug: 'Bab',
  bul: 'Глава',
  bur: 'အပိုင်',
  byn: 'መሕተ',
  cad: 'Ghágsa',
  cai: 'Chitlatli',
  car: 'Ayö',
  cat: 'Capítol',
  cau: 'Kapitullu',
  ceb: 'Kapitulo',
  cel: 'Kapitola',
  cha: 'Pánupw',
  chb: 'Bidi',
  che: 'Килсам',
  chg: 'د جلد',
  chi: '章',
  chk: 'Mweien',
  chm: 'Кепчеч',
  chn: 'Kapitel',
  cho: 'Chafo',
  chp: 'Įyąų',
  chr: 'ᎩᏂᏣᏍᏙᏗ',
  chu: 'Глава',
  chv: 'Кам',
  chy: 'Hokwa',
  cmc: 'Kapitol',
  cnr: 'Kitap',
  cop: 'باب',
  cor: 'Kavos',
  cos: 'Capitulu',
  cpe: 'Chapit',
  cpf: 'Chapitre',
  cpp: 'Chapítulo',
  cre: 'ᑲᓇᑦ',
  crh: 'Bab',
  crp: 'Chapter',
  csb: 'Rozdzéł',
  cus: 'Chapter',
  cze: 'Kapitola',
  dak: 'WiyayAowapi',
  dan: 'Kapitel',
  dar: 'Глава',
  day: 'Šapitul',
  del: 'Īkwē',
  den: 'Ngeɛn',
  dgr: "Ts'obele",
  din: 'Omo',
  div: 'ޖުމުހޫރި',
  doi: "Ч'аукун",
  dra: 'Chapter',
  dsb: 'Kapitl',
  dua: 'Nkaa',
  dut: 'Hoofdstuk',
  dyu: 'Ntaarʼ',
  dzo: 'ཡི་གེ',
  efi: 'Esiarata',
  eka: 'Onoyomota',
  elx: 'Nigmel',
  eng: 'Chapter',
  epo: 'Ĉapitro',
  'es-419': 'Capítulo',
  est: 'Peatükk',
  ewe: 'Akã',
  ewo: 'Mvo',
  fan: 'Hapitkulu',
  fao: 'Kapittul',
  fat: 'Viloi',
  fij: 'Ivakatavakiliga',
  fil: 'Kabanata',
  fin: 'Luku',
  fiu: 'Luku',
  fon: 'Kipitula',
  fre: 'Chapitre',
  frr: 'Kapitel',
  frs: 'Kapitel',
  fry: 'Hûs',
  ful: 'Huuŋere',
  fur: 'Capitui',
  gaa: 'ታሪን',
  gay: 'Nematuan',
  gba: 'Kamoo',
  gem: 'Kapitel',
  geo: 'წინადადება',
  ger: 'Kapitel',
  gez: 'መጽሐፈ',
  gil: 'Raito',
  gla: 'Caibideil',
  gle: 'Caibidlín',
  glg: 'Capítulo',
  glv: 'Coayl',
  gon: 'Gachin',
  gor: 'Djuatan',
  got: 'Prâhi',
  grb: 'Kobo',
  gre: 'Κεφάλαιο',
  grn: 'Aty',
  gsw: 'Kapitel',
  guj: 'અધ્યાય',
  gwi: 'Shashne',
  hai: 'Tawca',
  hat: 'Chapit',
  hau: 'Bab',
  haw: 'Mokuna',
  heb: 'פרק',
  her: 'Okawa',
  hil: 'Kapitulo',
  him: 'Bab',
  hin: 'अध्याय',
  hit: 'Pûşu',
  hmn: 'Rab',
  hmo: 'Kapitolo',
  hrv: 'Poglavlje',
  hsb: 'Kapitl',
  hun: 'Fejezet',
  hup: 'Hatung',
  iba: 'ᎤᎵᏰᏙᎢ',
  ibo: 'Mkpu',
  ice: 'Kafli',
  ido: 'Kapitolo',
  iii: 'ꀕꆨꌠ',
  ijo: 'Bibwe',
  iku: 'ᐊᒥᖅ',
  ile: 'Kapitolo',
  ilo: 'Capitulo',
  ina: 'Capitulo',
  inc: 'पर्याय',
  ind: 'Bab',
  ine: 'Kapitolo',
  inh: 'Тамар',
  ipk: 'Aturleq',
  ira: 'باب',
  iro: 'Chapter',
  'it-CH': 'Capitolo',
  ita: 'Capitolo',
  'ja-Hira': 'チャプター',
  jav: 'Bab',
  jbo: 'lo saclu',
  jpn: '章',
  jpr: 'Bargak',
  jrb: 'باب',
  kaa: 'Bab',
  kab: 'Tasniremt',
  kac: 'Pap',
  kal: 'Kapitula',
  kam: 'Kati',
  kan: 'ಅಧ್ಯಾಯ',
  kar: 'Bab',
  kas: 'باب',
  kau: 'Kāpuka',
  kaw: 'Bab',
  kaz: 'Глава',
  kbd: 'Басхьа',
  kha: 'Kaw',
  khi: 'Chapter',
  khm: 'ជំរុញ',
  kho: 'Papor',
  kik: 'Sura',
  kin: 'Igice',
  kir: 'Глава',
  kmb: 'Nsimba',
  kok: 'अध्याय',
  kom: 'Глава',
  kon: 'Lipɛlɛ',
  kor: '장',
  kos: 'Ngebako',
  kpe: 'Papa',
  krc: 'Глава',
  krl: 'Bab',
  kro: 'Chapter',
  kru: 'Джанмар',
  kua: 'Kapitul',
  kum: 'Какаб',
  kur: 'Pêl',
  kut: 'Bab',
  lad: 'Kapitulu',
  lah: 'پرھ',
  lam: 'Chapitre',
  lao: 'ບົດ',
  lat: 'Caput',
  lav: 'Nodaļa',
  lez: 'Коммаг',
  lim: 'Hoofdstök',
  lin: 'Koyoka',
  lit: 'Skyrius',
  lol: 'Mufele',
  loz: 'Luganzo',
  ltz: 'Kapitel',
  lua: 'Mbo',
  lub: 'Lupítula',
  lug: 'Kapituro',
  lui: 'Poliye',
  lun: 'Munwe',
  luo: 'Omilo',
  lus: 'Fas',
  mac: 'Глава',
  mad: 'Bab',
  mag: 'अध्याय',
  mah: 'Alomeni',
  mai: 'पांड।',
  mak: 'Pāttamasa',
  mal: 'അദ്ധ്യായം',
  man: 'Coyunu',
  mao: 'Tea',
  map: 'Pimël',
  mar: 'अध्याय',
  mas: 'Ikapítulu',
  may: 'Bab',
  mdf: 'Глава',
  mdr: 'Bab',
  men: 'Kopidzi',
  mic: 'Paamiskad',
  min: 'Bab',
  mkh: 'Chapter',
  mlg: 'Fitapitany',
  mlt: 'Kapitolu',
  mnc: 'ᠪᠣᠣᠷ',
  mni: 'ꯏꯁꯤ',
  mno: 'Chapter',
  moh: 'Rotinonhsón:ni',
  mon: 'Глав',
  mos: 'Kapitulo',
  mun: 'Chapter',
  mus: 'Hutke',
  mwl: 'Capítulo',
  mwr: 'ਬਾਬ',
  myn: 'Chapter',
  myv: 'Тикс',
  nah: 'Nican',
  nai: 'Chapter',
  nap: 'Capitolo',
  nau: 'Kapter',
  nav: 'Kéyági',
  nbl: 'Iqela',
  nde: 'Chirewo',
  ndo: 'Kapitu',
  nep: 'अध्याय',
  new: 'अध्याय',
  nia: 'Bapa',
  nic: 'Chapter',
  niu: 'Fakakotau',
  nno: 'Kapittel',
  nob: 'Kapittel',
  nog: 'Глава',
  non: 'Kapítuli',
  nor: 'Kapittel',
  nqo: '𞉬𞉤𞉬𞉤𞉭𞉳',
  nso: 'Mongwalo',
  nub: 'Chapter',
  nya: 'Dibhapu',
  nym: 'Kipfaasibapiti',
  nyn: 'Bwalo',
  nyo: 'Kipitalo',
  nzi: 'Nkongo',
  oci: 'Capítol',
  oji: 'ᐊᑲᒣᓇ',
  ori: 'ଅଧ୍ୟାୟ',
  orm: 'Qorma',
  osa: 'Kapitol',
  oss: 'Уырӕд',
  oto: 'Chapter',
  paa: 'Chapter',
  pag: 'Pagkapunay',
  pal: 'پچا',
  pam: 'Kapítulu',
  pan: 'ਅਧਿਆਯ',
  pap: 'Kapítulo',
  pau: 'Bab',
  per: 'باب',
  phi: 'Chapter',
  phn: '𐤀𐤋𐤏',
  pli: 'อัญญวน',
  pol: 'Rozdział',
  pon: 'Chapter',
  por: 'Capítulo',
  pra: 'Chapter',
  pro: 'Capitol',
  'pt-BR': 'Capítulo',
  pus: 'پېښه',
  que: 'Willay',
  raj: 'Bab',
  rap: 'Rap',
  rar: 'Kapitaura',
  roh: 'Kapitel',
  rom: 'Sar',
  rum: 'Capitol',
  run: 'Chapter',
  rup: 'Kapitel',
  rus: 'Глава',
  sad: 'Bab',
  sag: 'Fasal',
  sah: 'Бааба',
  sai: 'Chapter',
  sal: 'Chapter',
  san: 'अध्याय',
  sas: 'Bab',
  sat: 'ᱜᱟᱭᱥᱤᱞ',
  scn: 'Càpitulu',
  sco: 'Chapter',
  sel: 'Bab',
  sgn: 'Chapter',
  shn: 'ၵေႃႉ',
  sid: 'Bab',
  sin: 'පටස්ථානය',
  sio: 'Chapter',
  sit: 'Chapter',
  sla: 'Chapter',
  slo: 'Kapitola',
  slv: 'Poglavje',
  sma: 'Kapitel',
  sme: 'Kapitála',
  smi: 'Kapitála',
  smj: 'Kapitála',
  smn: 'Kapitála',
  smo: 'Kapitála',
  sms: 'Kapitála',
  sna: 'Ruregerero',
  snd: 'باب',
  snk: 'Nkokool',
  sog: 'Bab',
  som: 'Qayb',
  son: 'Danta',
  sot: 'Ditaba',
  spa: 'Capítulo',
  srd: 'Capítulu',
  srn: 'Kapitel',
  srp: 'Глава',
  srr: 'Chapter',
  ssa: 'Chapter',
  ssw: 'Sikufele',
  suk: 'Papa',
  sun: 'Bab',
  sus: 'Hoo',
  swa: 'Sura',
  swe: 'Kapitel',
  syr: 'باب',
  tah: 'Chapter',
  tai: 'Chapter',
  tam: 'அத்தியாயம்',
  tat: 'Глава',
  tel: 'అధ్యాయం',
  tem: 'Gono',
  ter: 'Bab',
  tet: 'Kapitulu',
  tgk: 'Боб',
  tgl: 'Kabanata',
  tha: 'บท',
  tib: 'ལྕགས་པ',
  tig: 'Kapitulo',
  tir: 'ክፍል',
  tiv: 'Bor',
  tkl: 'Kabon',
  tli: 'Chapter',
  tmh: 'Haddet',
  tog: 'Kapitolo',
  ton: 'Fakatokanga',
  tpi: 'Wanpela',
  tsi: 'Chapter',
  tsn: 'Chapter',
  tso: 'Kapiting',
  tuk: 'Bab',
  tum: 'Buku',
  tup: 'Chapter',
  tur: 'Bölüm',
  tut: 'Chapter',
  tvl: 'Kapitel',
  twi: 'Aboa',
  tyv: 'Чааха',
  udm: 'Глава',
  uga: 'Chapter',
  uig: 'باب',
  ukr: 'Глава',
  umb: 'Bab',
  urd: 'باب',
  uzb: 'Bab',
  vai: 'அதிசயம்',
  ven: 'Kufiyufiyu',
  vie: 'Chương',
  vol: 'Kapitul',
  vot: 'Pudok',
  wak: 'Chapter',
  wal: 'Bar',
  war: 'Kapítulo',
  was: 'Pam',
  wel: 'Pennod',
  wen: 'Kapitel',
  wln: 'Cåpitole',
  wol: 'Ndaxte',
  xal: 'Кинн',
  xho: 'Ubuxolelana',
  yao: 'Bab',
  yap: 'Thwaar',
  yid: 'פֿון',
  yor: 'Ise',
  ypk: 'Chapter',
  zap: 'Capítulo',
  zen: 'Kapitulu',
  zgh: 'الباب',
  'zh-Hant': '章',
  zha: 'Gzɯ',
  znd: 'Chapter',
  zul: 'Iyekathi',
  zun: 'Hózhǫ́ǫ́góó',
  zza: 'Bab',
};

export const theWordChapterTranslatedTo = (ietfLanguageTag?: string): string => {
  if (ietfLanguageTag == null) {
    return 'Chapter';
  }

  return CHAPTER_TRANSLATIONS[ietfLanguageTag] ?? 'Chapter';
};
