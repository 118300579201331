import { ComponentChildren, createContext, h, JSX } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { getWistiaLocalStorage } from '../../../utilities/wistiaLocalStorage.js';
import { ScrollStrategy } from '../types.ts';
import { getScrollStrategy, maybeAutoScroll } from '../utilities/autoScrollUtilities.ts';
import { useTranscriptContext } from './useTranscriptContext.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

type ScrollStrategyContextType = {
  scrollStrategy: ScrollStrategy;
  setScrollStrategy: (scrollStrategy: ScrollStrategy) => void;
};

const ScrollStrategyContext = createContext<ScrollStrategyContextType | null>(null);

type ScrollStrategyProviderProps = {
  children: ComponentChildren;
};

export const ScrollStrategyProvider = ({ children }: ScrollStrategyProviderProps): JSX.Element => {
  const { transcriptScrollContainer, autoScrollMarginTopPx, hasAutoScrollControl } =
    useTranscriptContext();
  const [scrollStrategy, setScrollStrategy] = useState<ScrollStrategy>('within-embed');
  const {
    playerState,
    currentWordElement,
    isAutoScrollEnabled,
    isManuallyScrollingNow,
    shouldNextAutoScrollBeInstant,
    isAutoScrollingNow,
  } = useTranscriptSignals();

  useEffect(() => {
    if (transcriptScrollContainer == null) {
      return undefined;
    }

    const observer = new ResizeObserver(() => {
      setScrollStrategy(getScrollStrategy({ transcriptScrollContainer }));
    });
    observer.observe(transcriptScrollContainer);

    const timeout = setTimeout(() => {
      // Wait a bit to check the initial scroll strategy since it may take a
      // while for the transcript to render. This is a hack and the delay may be
      // insufficient.
      const theScrollStrategy = getScrollStrategy({ transcriptScrollContainer });
      setScrollStrategy(theScrollStrategy);
      if (theScrollStrategy === 'within-embed' && hasAutoScrollControl) {
        // Avoid scrolljacking the page. If the viewer wants autoscroll when the
        // scrollStrategy is 'whole-page', they must interact with the
        // transcript.
        //
        // Also, respect the user previously having disabled auto-scroll.
        if (
          (getWistiaLocalStorage() as Record<string, unknown>).isTranscriptAutoScrollEnabled !==
          false
        ) {
          isAutoScrollEnabled.value = true;
        }
      }
    }, 1000);

    return () => {
      observer.disconnect();
      clearTimeout(timeout);
    };
  }, [transcriptScrollContainer]);

  useEffect(() => {
    if (transcriptScrollContainer == null) {
      return;
    }
    maybeAutoScroll({
      behavior: 'instant',
      transcriptScrollContainer,
      scrollStrategy,
      autoScrollMarginTopPx,
      playerState: playerState.value,
      onAutoScroll: () => {
        isAutoScrollingNow.value = true;
        shouldNextAutoScrollBeInstant.value = false;
      },
      currentWordElement: currentWordElement.value,
      isAutoScrollEnabled: isAutoScrollEnabled.value,
      isManuallyScrollingNow: isManuallyScrollingNow.value,
      shouldNextAutoScrollBeInstant: shouldNextAutoScrollBeInstant.value,
    });
  }, [
    scrollStrategy,
    currentWordElement.value,
    isAutoScrollEnabled.value,
    isManuallyScrollingNow.value,
    shouldNextAutoScrollBeInstant.value,
    isAutoScrollingNow.value,
  ]);

  const value = {
    scrollStrategy,
    setScrollStrategy,
  };

  return <ScrollStrategyContext.Provider value={value}>{children}</ScrollStrategyContext.Provider>;
};

export const useScrollStrategy = (): ScrollStrategyContextType => {
  const context = useContext(ScrollStrategyContext);
  if (context == null) {
    throw new Error('useScrollStrategy must be used within a ScrollStrategyProvider');
  }
  return context;
};
