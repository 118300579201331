import { Signal, signal } from '@preact/signals';
import { PlayerState } from '../../../types/player-api-types.ts';

export type TranscriptSignals = {
  currentWordElement: Signal<HTMLElement | null>;
  isAutoScrollEnabled: Signal<boolean>;
  isAutoScrollingNow: Signal<boolean>;
  isManuallyScrollingNow: Signal<boolean>;
  mouseIsOverTranscript: Signal<boolean>;
  playerState: Signal<PlayerState>;
  shouldHighlight: Signal<boolean>;
  shouldNextAutoScrollBeInstant: Signal<boolean>;
  timedWordElementsInCurrentCue: Signal<HTMLElement[]>;
};

export const createSignals = (): TranscriptSignals => ({
  currentWordElement: signal<HTMLElement | null>(null),
  isAutoScrollEnabled: signal(false),
  isAutoScrollingNow: signal(false),
  isManuallyScrollingNow: signal(false),
  mouseIsOverTranscript: signal(false),
  playerState: signal<PlayerState>('beforeplay'),
  shouldHighlight: signal(false),
  shouldNextAutoScrollBeInstant: signal<boolean>(true),
  timedWordElementsInCurrentCue: signal<HTMLElement[]>([]),
});
