import { h, JSX, createContext, ComponentChildren } from 'preact';
import { useContext } from 'preact/hooks';
import { TranscriptSignals } from '../utilities/createSignals.ts';

const TranscriptSignalsContext = createContext<TranscriptSignals | null>(null);

export const useTranscriptSignals = (): TranscriptSignals => {
  const context = useContext(TranscriptSignalsContext);
  if (context == null) {
    throw new Error('useTranscriptSignals must be used within a TranscriptSignalsProvider');
  }
  return context;
};

export const TranscriptSignalsProvider = ({
  children,
  signals,
}: {
  children: ComponentChildren;
  signals: TranscriptSignals;
}): JSX.Element => {
  return (
    <TranscriptSignalsContext.Provider value={signals}>
      {children}
    </TranscriptSignalsContext.Provider>
  );
};
