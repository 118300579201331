import { Fragment, h, JSX } from 'preact';
import { Nilable } from '@wistia/type-guards';
import { useEffect, useRef } from 'preact/hooks';
import { countMetric } from '../../utilities/simpleMetrics.js';
import { useTranscriptData } from './hooks/useTranscriptData.ts';
import { TranscriptStyles } from './components/TranscriptStyles.tsx';
import { useWistiaPlayerContext } from './hooks/useWistiaPlayerContext.tsx';
import { Font } from '../../types/font.ts';
import { useLoadFont } from '../shared/hooks/useLoadFont.ts';
import { MemoizedTranscriptDocumentRenderer } from './components/TranscriptDocumentRenderer.tsx';
import { DownloadButton } from './components/DownloadButton.tsx';
import { AutoScrollToggle } from './components/AutoScrollToggle.tsx';
import { useAutoScroll } from './hooks/useAutoScroll.ts';
import { useCurrentWordAndCueTracking } from './hooks/useCurrentWordAndCueTracking.ts';
import { HoverButtonsContainer } from './components/HoverButtons/HoverButtonsContainer.tsx';
import { useTranscriptKeyboardNavigation } from './hooks/useTranscriptKeyboardNavigation.tsx';
import { useTranscriptContext } from './hooks/useTranscriptContext.tsx';
import { usePlayerCaptionsLanguage } from './hooks/usePlayerCaptionsLanguage.tsx';
import { useTranscriptSignals } from './hooks/useTranscriptSignals.tsx';

export const TRANSCRIPT_DOCUMENT_CONTAINER_STYLES = `
  .transcript-document-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 4px;
    padding: 2px;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const TRANSCRIPT_SCROLL_CONTAINER_STYLES = `
  .transcript-scroll-container {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
  }
`;

const HoverButtons = ({
  mediaHashedId,
  embedHost,
  hasDownloadControl,
  hasAutoScrollControl,
}: {
  embedHost: Nilable<string>;
  hasAutoScrollControl: boolean;
  hasDownloadControl: boolean;
  mediaHashedId: string;
}): JSX.Element | null => {
  const hasAnyHoverControls = hasDownloadControl || hasAutoScrollControl;

  if (!hasAnyHoverControls) {
    return null;
  }

  return (
    <HoverButtonsContainer>
      {hasDownloadControl && <DownloadButton mediaHashedId={mediaHashedId} embedHost={embedHost} />}
      {hasAutoScrollControl && <AutoScrollToggle />}
    </HoverButtonsContainer>
  );
};

export type TranscriptProps = {
  accentColor?: Nilable<string>;
  embedHost?: Nilable<string>;
  font?: Font;
  onInitialRender?: () => void;
  playerDomId?: Nilable<string>;
  shadowRoot: ShadowRoot;
};

export const Transcript = ({
  accentColor,
  embedHost,
  font,
  onInitialRender,
  shadowRoot,
}: TranscriptProps): JSX.Element => {
  const { setTranscriptScrollContainer, mediaHashedId, hasDownloadControl, hasAutoScrollControl } =
    useTranscriptContext();
  const { mouseIsOverTranscript } = useTranscriptSignals();
  const transcriptScrollContainerRef = useRef<HTMLDivElement>(null);
  const { onTranscriptKeyDown, onTranscriptClick, KeyboardNavDialog } =
    useTranscriptKeyboardNavigation({
      shadowRoot,
    });
  useLoadFont(font);
  const { audioDescriptionLanguage } = useWistiaPlayerContext();
  const { playerCaptionsLanguage } = usePlayerCaptionsLanguage();
  const { transcriptDocument, captionsData } = useTranscriptData({
    mediaHashedId,
    transcriptIetfLanguageTag: playerCaptionsLanguage,
    audioDescriptionIetfLanguageTag: audioDescriptionLanguage,
    embedHost,
  });

  useCurrentWordAndCueTracking({ captionsData, transcriptDocument });
  useAutoScroll();

  useEffect(() => {
    if (!transcriptScrollContainerRef.current) {
      return;
    }
    setTranscriptScrollContainer(transcriptScrollContainerRef.current);
  }, [transcriptScrollContainerRef.current]);

  useEffect(() => {
    countMetric('transcript/initial-render', 1, {
      mediaHashedId,
      embedHost,
    });
    onInitialRender?.();
  }, []);

  const isFontAGoogleFont = (font?.url ?? '').length === 0;

  return (
    <Fragment>
      <HoverButtons
        mediaHashedId={mediaHashedId}
        embedHost={embedHost}
        hasDownloadControl={hasDownloadControl}
        hasAutoScrollControl={hasAutoScrollControl}
      />
      <KeyboardNavDialog />
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={transcriptScrollContainerRef}
        className="transcript-scroll-container"
        onKeyDown={onTranscriptKeyDown}
        onClick={onTranscriptClick}
        onMouseEnter={() => {
          mouseIsOverTranscript.value = true;
        }}
        onMouseLeave={() => {
          mouseIsOverTranscript.value = false;
        }}
      >
        <TranscriptStyles
          accentColor={accentColor}
          fontFamily={font?.family ?? 'Inter'}
          hasGoogleFont={isFontAGoogleFont}
        />
        <div className="transcript-document-container">
          {/* Setting the key prop to playerCaptionsLanguage causes a full remount
        of MemoizedTranscriptDocumentRenderer when that prop changes. We do this
        to avoid an error that can result from a collision between Preact's DOM
        manipulation and our own that happens in useCurrentCueDOMManipulator,
        which breaks cue and word highlighting. This fix is a bit of a hack. */}
          <MemoizedTranscriptDocumentRenderer
            transcriptDocument={transcriptDocument}
            key={`${playerCaptionsLanguage}-${audioDescriptionLanguage}`}
          />
        </div>
      </div>
    </Fragment>
  );
};
